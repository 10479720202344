import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'cardNumber',
      label: 'card.card-no'
    },
    {
      prop: 'cardName',
      label: 'card.card-name'
    },
    {
      prop: 'cardType',
      label: 'common.app-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'card_type')
      }
    },
    {
      prop: 'status',
      label: 'common.app-status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },

    {
      label: 'common.app-operation',
      width: '400',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
