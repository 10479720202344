import { getCommonParamsOptions } from '@/utils/common'

export const searchFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'cardNumber',
      type: 'input',
      label: 'card.card-no'
    },
    {
      field: 'cardName',
      type: 'input',
      label: 'card.card-name'
    },
    {
      field: 'cardType',
      type: 'select',
      label: 'common.app-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('card_type')
    },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status')
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
