<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-card')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <!-- <template #otherHandler="scope">
        <template v-if="isLock || isUnLock">
          <el-button
            v-if="scope.row.status === cardStatus.lock"
            class="opt-btn"
            icon="el-icon-unlock"
            size="mini"
            plain
            @click="handleLockClick(scope.row)"
            >{{ $t('general.unlock') }}</el-button
          >
          <el-button
            v-else
            class="opt-btn"
            icon="el-icon-lock"
            size="mini"
            plain
            @click="handleLockClick(scope.row)"
            >{{ $t('general.lock') }}</el-button
          >
        </template>
      </template> -->
      <template #otherHandler="scope">
        <el-button
          v-if="isSwitch && isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          type="warning"
          plain
          @click="handleDisableClick(scope.row)"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="isSwitch && !isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row)"
          >{{ $t('general.enable') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/card.search'
import { contentTableConfig } from './config/card.table'

import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'

// import { cardSatusValue, cardStatus } from '@/utils/constant/const.js'

// 表格基础配置项
const tableOptions = {
  moduleName: 'parameter', // 所属的模块
  pageName: 'card', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  pageAction: 'param/getCardLists', // 获取表格的Action
  deleteRecordAction: 'param/handleDeleteCard', // 删除record的Action
  pageCountGetter: 'param/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'param/pageListData' // 获取表格列表数据getters,
}

// const isLock = usePermission(
//   tableOptions.moduleName,
//   tableOptions.pageName,
//   'lock'
// )
// const isUnLock = usePermission(
//   tableOptions.moduleName,
//   tableOptions.pageName,
//   'unlock'
// )

const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)
const pageSearchRef = ref('')

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')

const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/card/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/card/edit/${id}`)
}

const handleNewData = (id) => {
  router.push(`/card/new`)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}

// const handleLockClick = async (row) => {
//   const form = {
//     id: row.id,
//     status:
//       row.status === cardStatus.lock
//         ? cardSatusValue.unlock
//         : cardSatusValue.lock
//   }
//   handleMessageCommit('param/handleEditCardAction', form).then(() => {
//     pageContentRef.value.getPageData()
//   })
// }

const isNormalStatus = (status) => {
  return status === '00'
}
const handleDisableClick = async (row) => {
  handleMessageCommit('param/handleTerminalCardDisableAction', row.id).then(
    () => {
      pageContentRef.value.getPageData()
    }
  )
}
const handleEnableClick = async (row) => {
  handleMessageCommit('param/handleTerminalCardEnableAction', row.id).then(
    () => {
      pageContentRef.value.getPageData()
    }
  )
}
</script>
